<template>
  <InfoWraper>
    <div class="nav-author">
      <sdDropdown
        placement="bottomRight"
        :action="['click']"
      >
        <template #overlay>
          <NavAuth>
            <router-link
              to="#"
              @click="() => onFlagChangeHandle('turkish')"
            >
              <img
                :src="require('@/static/img/flag/turkey-circle.png')"
                style="height: 17px"
                alt=""
              >
            </router-link>
            <router-link
              to="#"
              @click="() => onFlagChangeHandle('english')"
            >
              <img
                :src="require('@/static/img/flag/england-circle.png')"
                style="height: 17px"
                alt=""
              >
            </router-link>
          </NavAuth>
        </template>
        <a
          to="#"
          class="head-example"
        >
          <img
            :src="imagePathRoute"
            alt=""
            style="max-width: 25px !important;"
          >
        </a>
      </sdDropdown>
      <a
        class="user-dropdwon__bottomAction"
        style="margin-left: 10px"
        href="#"
        @click="SignOut"
      >
        <LogoutOutlined />
        {{ $t.logOut }}</a>
    </div>
  </InfoWraper>
</template>
<script>
import { InfoWraper, NavAuth } from './auth-info-style'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { LogoutOutlined } from '@ant-design/icons-vue'
import { avaibleLangs } from '@/utility/language'

export default {
  name: 'AuthInfo',
  components: {
    InfoWraper,
    NavAuth,
    LogoutOutlined,
  },
  setup() {

    const router = useRouter()

    const SignOut = e => {
      e.preventDefault()
      router.push('/auth/login')
      localStorage.removeItem('jwtToken')
    }

    return {
      SignOut,
    }
  },
  data() {
    return {
      flag: 'english',
      imagePath: '',
      avaibleLangs,
      currentLang: localStorage.getItem('lang') || 'TR',
    }
  },
  computed: {
    imagePathRoute() {
      const lang = localStorage.getItem('lang')
      if (lang !== null) {
        return require(`@/static/img/flag/${lang === 'TR' ? 'turkey' : 'england'}-circle.png`)
      } else {
        return require(`@/static/img/flag/turkey-circle.png`)
      }
    },
  },
  methods: {
    onFlagChangeHandle: function(value) {
      if (value === 'turkish') {
        localStorage.setItem('lang', 'TR')
      } else {
        localStorage.setItem('lang', 'EN')
      }
      this.$router.go()
    },
  },
}
</script>

<style>
.flag {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.flagCover {
  margin: 0px 0px !important;
}

.turkishFlag {
  background-color: #FD8A8A;
  color: #ffffff !important;
  cursor: pointer;
}

.turkishFlag:hover {
  background-color: #ffffff;
  color: #FD8A8A !important;
  cursor: pointer;
}

.englishFlag {
  background-color: #7692c2;
  color: #ffffff !important;
  cursor: pointer;
}

.englishFlag:hover {
  background-color: #ffffff;
  color: #7692c2 !important;
  cursor: pointer;
}
</style>