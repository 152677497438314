<template>
  <a-menu
    v-model:openKeys="openKeys"
    v-model:selectedKeys="selectedKeys"
    :mode="mode"
    :theme="darkMode ? 'dark' : 'light'"
  >
    <!-- tsoft-kargo menu items -->

    <a-menu-item
      v-if="showTsoftKargoMenuItem()"
      key="contracted-panel"
      @click="toggleCollapsed"
    >
      <router-link :to="{ name: 'T.Index.Page' }">
        <sdFeatherIcons type="maximize" />
        <span> {{ $t.contractedPanel }} </span>
      </router-link>
    </a-menu-item>

    <a-menu-item
      v-if="showTsoftKargoMenuItem()"
      key="tsoft-kargo-records"
      @click="toggleCollapsed"
    >
      <router-link :to="{ name: 'T.ShipmentList.Page' }">
        <sdFeatherIcons type="truck" />
        <span> {{ $t.cargo_records }} </span>
      </router-link>
    </a-menu-item>

    <a-menu-item
      v-if="showTsoftKargoMenuItem()"
      key="settings"
      @click="toggleCollapsed"
    >
      <router-link :to="{ name: 'O.Settings.Page' }">
        <sdFeatherIcons type="settings" />
        <span> {{ $t.settings }} </span>
      </router-link>
    </a-menu-item>

    <a-menu-item
      v-if="showTsoftKargoMenuItem()"
      key="contracted-cargo"
      @click="toggleCollapsed"
    >
      <router-link :to="{ name: 'T.CargoPrices.Page' }">
        <sdFeatherIcons type="credit-card" />
        <span> {{ $t.cargoPrices }} </span>
      </router-link>
    </a-menu-item>


    <a-menu-item
        v-if="showTsoftKargoMenuItem()"
        key="company-map"
        @click="toggleCollapsed"
    >
      <router-link :to="{ name: 'C.ShippingCompanyMap.Page' }">
        <sdFeatherIcons type="repeat" />
        <span> {{ $t.cargoCompanyMapping }} </span>
      </router-link>
    </a-menu-item>

    <a-menu-item
      v-if="showTsoftKargoMenuItem()"
      key="send-manual-cargo"
      @click="toggleCollapsed"
    >
      <router-link :to="{ name: 'T.SendManualCargo.Page' }">
        <sdFeatherIcons type="send" />
        <span> {{ $t.cargoForm }} </span>
      </router-link>
    </a-menu-item>

    <a-menu-item
      v-if="showTsoftKargoMenuItem()"
      key="receipt"
      @click="toggleCollapsed"
    >
      <router-link :to="{ name: 'T.Receipt.Page' }">
        <sdFeatherIcons type="activity" />
        <span> {{ $t.currentMovements }} </span>
      </router-link>
    </a-menu-item>

    <a-menu-item
      v-if="showTsoftKargoMenuItem()"
      key="faq"
      @click="toggleCollapsed"
    >
      <a
        href="https://kargo.tsoft.com.tr/index.php#frequently-asked-questions"
        target="_blank"
      >
        <sdFeatherIcons type="help-circle" />
        <span> {{ $t.frequentlyAskedQuestions }} </span>
      </a>
    </a-menu-item>

    <a-menu-item
      v-if="showTsoftKargoMenuItem()"
      key="help-desk"
      @click="toggleCollapsed"
    >
      <router-link :to="{ name: 'O.HelpDesk.Page' }">
        <sdFeatherIcons type="activity" />
        <span> {{ $t.helpDesk }} </span>
      </router-link>
    </a-menu-item>

    <!-- cargong menu items -->

    <a-menu-item
      v-if="showCargongMenuItem()"
      key="starter"
      @click="toggleCollapsed"
    >
      <router-link :to="{ name: 'C.Index.Page' }">
        <sdFeatherIcons type="airplay" />
        <span> {{ $t.panel }} </span>
      </router-link>
    </a-menu-item>

    <a-menu-item
      v-if="showCargongMenuItem()"
      key="cargong-records"
      @click="toggleCollapsed"
    >
      <router-link :to="{ name: 'C.ShipmentList.Page' }">
        <sdFeatherIcons type="truck" />
        <span> {{ $t.cargo_records }} </span>
      </router-link>
    </a-menu-item>

    <a-menu-item
      v-if="showCargongMenuItem()"
      key="payment"
      @click="toggleCollapsed"
    >
      <router-link :to="{ name: 'C.Payment.Page'}">
        <sdFeatherIcons type="credit-card" />
        <span> {{ $t.payment }} </span>
      </router-link>
    </a-menu-item>

    <a-menu-item
      v-if="showCargongMenuItem()"
      key="payment-history"
      @click="toggleCollapsed"
    >
      <router-link :to="{ name: 'C.PaymentHistory.Page' }">
        <sdFeatherIcons type="dollar-sign" />
        <span> {{ $t.payment_history }} </span>
      </router-link>
    </a-menu-item>

    <a-menu-item
      v-if="showCargongMenuItem()"
      key="company"
      @click="toggleCollapsed"
    >
      <router-link :to="{ name: 'C.ShipmentCompaniesSettings.Page' }">
        <sdFeatherIcons type="plus" />
        <span> {{ $t.addCargoCompany }} </span>
      </router-link>
    </a-menu-item>
    <a-menu-item
      v-if="showCargongMenuItem()"
      key="company-map"
      @click="toggleCollapsed"
    >
      <router-link :to="{ name: 'C.ShippingCompanyMap.Page' }">
        <sdFeatherIcons type="repeat" />
        <span> {{ $t.cargoCompanyMapping }} </span>
      </router-link>
    </a-menu-item>

    <a-menu-item
      v-if="showCargongMenuItem()"
      key="settings"
      @click="toggleCollapsed"
    >
      <router-link :to="{ name: 'O.Settings.Page' }">
        <sdFeatherIcons type="settings" />
        <span> {{ $t.settings }} </span>
      </router-link>
    </a-menu-item>

    <a-menu-item
      v-if="showCargongMenuItem()"
      key="wallet"
      @click="toggleCollapsed"
    >
      <router-link :to="{ name: 'C.Wallet.Page' }">
        <sdFeatherIcons type="credit-card" />
        <span> {{ $t.wallet }} </span>
      </router-link>
    </a-menu-item>

    <a-menu-item
      v-if="showCargongMenuItem()"
      key="help-desk"
      @click="toggleCollapsed"
    >
      <router-link :to="{ name: 'O.HelpDesk.Page' }">
        <sdFeatherIcons type="activity" />
        <span> {{ $t.helpDesk }} </span>
      </router-link>
    </a-menu-item>
  </a-menu>
</template>
<script>
import { computed, reactive, ref, toRefs, watch, watchEffect } from 'vue'
import VueTypes from 'vue-types'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import versions from '../demoData/changelog.json'

export default {
  name: 'AsideItems',
  props: {
    toggleCollapsed: VueTypes.func,
    events: VueTypes.object,
  },
  setup(props) {
    const store = useStore()
    const darkMode = computed(() => store.state.themeLayout.data)
    const mode = ref('inline')
    const { events } = toRefs(props)
    const {
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
    } = events.value

    const router = computed(() => useRoute())
    const state = reactive({
      selectedKeys: ['home'],
      openKeys: ['dashboard'],
      preOpenKeys: ['dashboard'],
    })

    const showTsoftKargoMenuItem = () => {
      const jwtContract = localStorage.getItem('jwtContract')

      return jwtContract === '1'
    }

    const showCargongMenuItem = () => {
      const jwtContract = localStorage.getItem('jwtContract')

      return jwtContract !== '1'
    }

    watchEffect(() => {
      if (router.value.matched.length) {
        if (router.value.matched.length > 2) {
          state.selectedKeys = [router.value.matched[2].name]
          state.openKeys = [router.value.matched[1].name]
          state.preOpenKeys = [router.value.matched[1].name]
        } else if (router.value.matched.length > 3) {
          state.selectedKeys = [router.value.matched[3].name]
          state.openKeys = [router.value.matched[1].name]
          state.preOpenKeys = [router.value.matched[1].name]
        } else {
          state.selectedKeys = [router.value.matched[1].name]
          state.openKeys = [router.value.matched[1].name]
          state.preOpenKeys = [router.value.matched[1].name]
        }
      }
    })

    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal
      },
    )

    return {
      mode,
      ...toRefs(state),
      darkMode,
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
      showTsoftKargoMenuItem,
      showCargongMenuItem,
      versions,
    }
  },
}
</script>