<template>
  <a-spin :spinning="loading">
    <TopMenuStyle>
      <div
        class="strikingDash-top-menu"
        style="font-size: 10pt"
      >
        <ul>
          <li
            class="subMenu>"
            style="margin-right: 10px !important;"
          >
            <a
              href="#"
              class="parent"
            >
              <sdFeatherIcons type="user" />
              <b style="display: inline-block; max-width: 90px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ headerData.userMail }}</b>
            </a>
          </li>
          <li
            class="subMenu>"
            style="margin-right: 10px !important;"
          >
            <a
              href="#"
              class="parent"
            >
              <sdFeatherIcons type="pie-chart" />
              {{ $t.usedAmount }} : {{ headerData.usedAmount }}
            </a>
          </li>
          <li
            class="subMenu>"
            style="margin-right: 10px !important;"
          >
            <a
              href="/member/tsoft-kargo/payment"
              class="parent"
            >
              <sdFeatherIcons type="layers" />
              {{ $t.remainAmount }} : {{ headerData.remainAmount }}
            </a>
          </li>
          <li class="subMenu>"
              style="margin-right: 10px !important;"
          >
            <a href="/member/tsoft-kargo/payment" class="btn-next ant-btn ant-btn-primary" style="color:white">
              {{__("makePayment", "Bakiye Yükle")}}
            </a>
          </li>
        </ul>
      </div>
    </TopMenuStyle>
  </a-spin>
</template>

<script>

import { TopMenuStyle } from './style'

import { defineComponent, onMounted } from 'vue'
import { useTopMenu } from '@/composables/useTopMenu'

export default defineComponent({
  name: 'TopMenuItems',
  components: {
    TopMenuStyle,
  },
  setup() {
    const { loading, headerData } = useTopMenu()

    onMounted(() => {

      const active = document.querySelector('.strikingDash-top-menu a.active')
      const activeDefault = () => {
        const megaMenu = active.closest('.megaMenu-wrapper')
        const hasSubMenuLeft = active.closest('.has-subMenu-left')
        if ( ! megaMenu) {
          active.closest('ul').previousSibling.classList.add('active')
          if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active')
        } else {
          active.closest('.megaMenu-wrapper').previousSibling.classList.add('active')
        }
      }
      window.addEventListener('load', active && activeDefault)

    })

    return {
      loading,
      headerData,
    }
  },
})

</script>