<template>
  <Div :dark-mode="darkMode">
    <Layout class="layout">
      <Header
        :style="{
          position: 'fixed',
          width: '100%',
          top: 0,
          [!rtl ? 'left' : 'right']: 0,
        }"
      >
        <a-row>
          <a-col
            :lg="!topMenu ? 4 : 3"
            :sm="6"
            :xs="12"
            class="align-center-v navbar-brand"
          >
            <sdButton
              v-if="!topMenu || innerWidth <= 991"
              type="white"
              @click="toggleCollapsed"
            >
              <img
                :src="require(`../static/img/icon/${collapsed ? 'right.svg' : 'left.svg'}`)"
                alt="menu"
              >
            </sdButton>
            <a
              :class="topMenu && innerWidth > 991 ? 'striking-logo top-menu' : 'striking-logo'"
              @click="gotoMainPage"
            >
              <img
                v-if="logoSrc"
                :src="require(`../img/${logoSrc}`)"
                alt="logo"
              >
            </a>
            <!-- :src="!darkMode ? require(`../static/img/Logo_Dark.svg`) : require(`../static/img/Logo_white.png`)" -->
          </a-col>
          <a-col
            :lg="!topMenu ? 14 : 15"
            :md="8"
            :sm="0"
            :xs="0"
          >
            <TopMenuItems v-if="innerWidth > 991" />
          </a-col>

          <a-col
            :lg="6"
            :md="10"
            :sm="0"
            :xs="0"
          >
            <TopMenuSearch v-if="topMenu && innerWidth > 991">
              <div class="top-right-wrap d-flex">
                <a
                  :class="`${activeSearch ? 'search-toggle active' : 'search-toggle'}`"
                  href="#"
                  @click="
                    () => {
                      toggleSearch()
                    }
                  "
                >
                  <sdFeatherIcons type="search" />
                  <sdFeatherIcons type="x" />
                </a>
                <div :class="`${activeSearch ? 'topMenu-search-form show' : 'topMenu-search-form'}`">
                  <form action="">
                    <span class="search-icon">
                      <sdFeatherIcons type="search" />
                    </span>
                    <input
                      type="text"
                      name="search"
                    >
                  </form>
                </div>
                <AuthInfo />
              </div>
            </TopMenuSearch>
            <AuthInfo v-else />
          </a-col>
          <a-col
            :style="{ position: 'static' }"
            :md="0"
            :sm="18"
            :xs="12"
          >
            <div class="mobile-action">
              <a
                class="btn-search"
                href="#"
                @click="handleSearchHide(searchHide)"
              >
                <sdFeatherIcons
                  v-if="searchHide"
                  type="search"
                />
                <sdFeatherIcons
                  v-else
                  type="x"
                />
              </a>
              <a
                class="btn-auth"
                href="#"
                @click="onShowHide(hide)"
              >
                <sdFeatherIcons type="more-vertical" />
              </a>
            </div>
          </a-col>
        </a-row>
      </Header>
      <div class="header-more">
        <a-row>
          <a-col
            :md="0"
            :sm="24"
            :xs="24"
          >
            <div class="small-screen-headerRight">
              <SmallScreenSearch
                :hide="searchHide"
                :dark-mode="darkMode"
              >
                <HeaderSearch />
              </SmallScreenSearch>
              <SmallScreenAuthInfo
                :hide="hide"
                :dark-mode="darkMode"
              >
                <AuthInfo :rtl="rtl" />
              </SmallScreenAuthInfo>
            </div>
          </a-col>
        </a-row>
      </div>
      <Layout>
        <template v-if="!topMenu || innerWidth <= 991">
          <Sider
            :width="280"
            :style="{
              margin: '63px 0 0 0',
              padding: '15px 15px 155px 15px',
              overflowY: 'auto',
              height: '100vh',
              position: 'fixed',
              [!rtl ? 'left' : 'right']: 0,
              zIndex: 998,
            }"
            :collapsed="collapsed"
            :theme="!darkMode ? 'light' : 'dark'"
          >
            <perfect-scrollbar
              :options="{
                wheelSpeed: 1,
                swipeEasing: true,
                suppressScrollX: true,
              }"
            >
              <AsideItems
                :toggle-collapsed="toggleCollapsedMobile"
                :top-menu="topMenu"
                :rtl="rtl"
                :dark-mode="darkMode"
                :events="onEventChange"
              />
            </perfect-scrollbar>
          </Sider>
        </template>
        <Layout class="atbd-main-layout">
          <Content>
            <Suspense>
              <template #default>
                <router-view />
              </template>
              <template #fallback>
                <div class="spin">
                  <a-spin />
                </div>
              </template>
            </Suspense>

            <Footer
              class="admin-footer"
              :style="{
                padding: '20px 30px 18px',
                color: 'rgba(0, 0, 0, 0.65)',
                fontSize: '14px',
                background: 'rgba(255, 255, 255, .90)',
                width: '100%',
                boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
              }"
            >
              <a-row>
                <a-col
                  :md="12"
                  :xs="24"
                >
                  <span class="admin-footer__copyright" />
                </a-col>
                <a-col
                  :md="12"
                  :xs="24"
                >
                  <div class="admin-footer__links">
                    <a
                      v-if="showTsoftItem()"
                      href="https://kargo.tsoft.com.tr/Kullanici-Sozlesmesi.pdf"
                      target="_blank"
                    >
                      Kullanıcı Sözleşmesi
                    </a>
                    <a href="#">2023</a>
                  </div>
                </a-col>
              </a-row>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  </Div>
</template>

<script>

import { Layout } from 'ant-design-vue'
import { Div, SmallScreenAuthInfo, SmallScreenSearch, TopMenuSearch } from './style'
import HeaderSearch from '../components/header-search/HeaderSearch'
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'

import AuthInfo from '../components/utilities/auth-info/info'
import AsideItems from './Aside'
import TopMenuItems from './TopMenuItems'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const { Header, Footer, Sider, Content } = Layout

export default {
  name: 'WithAdminLayout',
  components: {
    Div,
    Header,
    Layout,
    Footer,
    Sider,
    Content,
    HeaderSearch,
    SmallScreenSearch,
    SmallScreenAuthInfo,
    TopMenuSearch,
    AuthInfo,
    AsideItems,
    TopMenuItems,
    PerfectScrollbar,
  },
  setup() {

    const router = useRouter()

    const logoSrc = ref('')
    const collapsed = ref(false)
    const hide = ref(true)
    const searchHide = ref(true)
    const customizerAction = ref(false)
    const activeSearch = ref(false)

    // const store = useStore();
    const { dispatch, state } = useStore()

    const rtl = computed(() => state.themeLayout.rtlData)
    const darkMode = computed(() => state.themeLayout.data)
    const topMenu = computed(() => state.themeLayout.topMenu)

    collapsed.value = window.innerWidth <= 1200 && true

    const toggleCollapsed = e => {
      e.preventDefault()
      collapsed.value = ! collapsed.value
    }
    const handleSearchHide = search => {
      searchHide.value = ! search
      hide.value = true
    }
    const onShowHide = h => {
      hide.value = ! h
      searchHide.value = true
    }
    const toggleSearch = () => {
      activeSearch.value = ! activeSearch.value
    }

    const toggleCollapsedMobile = () => {
      if (innerWidth <= 990) {
        collapsed.value = false
      }
    }

    const onRtlChange = () => {
      const html = document.querySelector('html')
      html.setAttribute('dir', 'rtl')
      dispatch('changeRtlMode', true)
    }

    const onLtrChange = () => {
      const html = document.querySelector('html')
      html.setAttribute('dir', 'ltr')
      dispatch('changeRtlMode', false)
    }

    const modeChangeDark = () => {
      dispatch('changeLayoutMode', true)
    }

    const modeChangeLight = () => {
      dispatch('changeLayoutMode', false)
    }

    const modeChangeTopNav = () => {
      dispatch('changeMenuMode', true)
    }

    const modeChangeSideNav = () => {
      dispatch('changeMenuMode', false)
    }

    const onEventChange = {
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
    }

    const gotoMainPage = () => {
      if (localStorage.getItem('jwtContract') === '1') {
        router.replace({ name: 'T.Index.Page' })
      } else {
        router.replace({ name: 'C.Index.Page' })
      }
    }

    const showTsoftItem = () => {
      const jwtContract = localStorage.getItem('jwtContract')

      return jwtContract === '1'
    }

    onMounted(() => {
      if (localStorage.getItem('jwtContract') === '1') {
        logoSrc.value = 'tsoft-logo.png'
      } else {
        logoSrc.value = 'cargonglogo.png'
      }
    })

    return {
      logoSrc,
      toggleCollapsed,
      handleSearchHide,
      toggleCollapsedMobile,
      onShowHide,
      collapsed,
      hide,
      searchHide,
      toggleSearch,
      customizerAction,
      activeSearch,
      innerWidth: window.innerWidth,
      rtl,
      darkMode,
      topMenu,
      onEventChange,
      gotoMainPage,
      showTsoftItem,
    }
  },
}
</script>
<style>
.ps {
  height: calc(100vh - 100px);
}
</style>